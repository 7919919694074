import { AxiosError } from 'axios'
import React from 'react'

import { Button, Loader, Select } from 'packages/common'
import { IconName } from 'packages/iconic'
import { AsyncState } from 'packages/utils/hooks'

import { parseHkName } from 'app/hkhub/components/schedule/helpers/housekeeperHelpers'
import { Clean } from 'app/hkhub/store/cleans'

import { MandatoryHkNote } from '../MandatoryHkNote'
import { AssignmentEditorTranslations } from './AssignmentEditor.container'
import {
  AssignmentEditorState,
  HkSelectConfig,
  MAX_HKS,
} from './AssignmentEditor.reducer'
import { AssignmentSelectOptionStyles, St } from './AssignmentEditor.styles'
import {
  configToConfigDisplayName,
  getCleanRateForCleanType,
  getHkConfigOptions,
  hkOptionIsDisabled,
  makeHkSelectConfig,
} from './AssignmentEditor.utils'

export enum AssignmentEditorTestIds {
  container = 'AssignmentEditor__container',
  error = 'AssignmentEditor__editor',
  ineditableHk = 'AssignmentEditor__ineditableHk',
}

export type AssignmentEditorHandlers = {
  addHkSelect: () => void
  cancelEdit: () => void
  changeHk: (index: number) => (hk: HkSelectConfig) => void
  clearError: () => void
  removeHk: (index: number) => void
  save: () => void
}

export type AssignmentEditorProps = {
  clean: Clean
  handlers: AssignmentEditorHandlers
  requestState: AsyncState<unknown>
  state: AssignmentEditorState
  strings: AssignmentEditorTranslations
}

export const AssignmentEditor: React.FC<AssignmentEditorProps> = React.memo(
  ({ clean, handlers, requestState, state, strings }) => {
    const disableSubmit =
      requestState.loading ||
      state.isInvalidTeamClean ||
      !state.hasUnsavedChanges

    const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

    const getSelectedValue = hk => {
      return hk
        ? makeHkSelectConfig(state.assignmentRejectionReasonMap, strings)(hk)
        : null
    }

    const rateForCleanType = getCleanRateForCleanType(clean.jobType, clean.unit)

    // Because we are returning React Fragments for the label in the configToConfigDisplayName function
    // we need a custom function to filter options
    const filterOption = (
      candidate: { data: HkSelectConfig; label: string; value: HkSelectConfig },
      input: string,
    ) => {
      return candidate.value.displayName
        .toLowerCase()
        .includes(input.toLowerCase())
    }

    React.useEffect(() => {
      if (requestState.error instanceof AxiosError) {
        setErrorMessage(
          requestState.error.response?.data.errors[0].title || null,
        )
      } else {
        setErrorMessage(null)
      }
    }, [requestState.error])

    const hasBeenStarted = !!clean.startedAt

    return (
      <St.AssignmentEditorContainer
        data-testid={AssignmentEditorTestIds.container}
      >
        <St.EditableSectionTitle>{strings.assignment}</St.EditableSectionTitle>
        {requestState.loading && <Loader />}

        <St.EditableSection>
          <div>
            {state.selectedHousekeepers.map((hk, index) => {
              const isOriginalHk = state.originalHousekeeperIds.includes(
                hk?.id ?? 'FALSE',
              )

              return (
                <St.HKAssignmentWrapper key={index}>
                  {isOriginalHk && hasBeenStarted ? (
                    hk ? (
                      <div data-testid={AssignmentEditorTestIds.ineditableHk}>
                        {parseHkName(hk)}
                      </div>
                    ) : null
                  ) : (
                    <>
                      <Select
                        filterOption={filterOption}
                        fullWidth={true}
                        getOptionLabel={config =>
                          configToConfigDisplayName(
                            config,
                            state.housekeepers,
                            rateForCleanType,
                            strings.rejected,
                          )
                        }
                        isOptionDisabled={option =>
                          hkOptionIsDisabled(
                            option,
                            state.housekeepers,
                            rateForCleanType,
                          )
                        }
                        onChange={handlers.changeHk(index)}
                        options={getHkConfigOptions(state, strings)}
                        placeholder={strings.selectHousekeeper}
                        selectedValue={getSelectedValue(hk)}
                        style={AssignmentSelectOptionStyles}
                      />
                      <St.HKRemoveButton
                        icon={IconName.timesCircle}
                        onClick={() => handlers.removeHk(index)}
                        size={20}
                      />
                    </>
                  )}
                </St.HKAssignmentWrapper>
              )
            })}
          </div>

          {state.isInvalidTeamClean && (
            <St.Alert alertType={'danger'}>{strings.invalidTeamClean}</St.Alert>
          )}

          {hasBeenStarted && (
            <St.Alert alertType={'info'}>{strings.cleanStartedAlert}</St.Alert>
          )}
          <MandatoryHkNote clean={clean} />

          {state.selectedHousekeepers.length < MAX_HKS && (
            <St.AddHousekeeper onClick={handlers.addHkSelect}>
              {`+ ${strings.addAssignee}`}
            </St.AddHousekeeper>
          )}
        </St.EditableSection>

        <div>
          {requestState.error && (
            <St.Alert
              alertType={'danger'}
              dataTestId={AssignmentEditorTestIds.error}
              onClose={handlers.clearError}
            >
              <span>{errorMessage || strings.assignmentChangeError}</span>
            </St.Alert>
          )}

          <St.Buttons>
            <Button onClick={handlers.cancelEdit} buttonType={'utility'}>
              {strings.cancel}
            </Button>

            <Button
              disabled={disableSubmit}
              onClick={handlers.save}
              buttonType={'primary'}
            >
              {strings.save} {strings.clean}
            </Button>
          </St.Buttons>
        </div>
      </St.AssignmentEditorContainer>
    )
  },
)
