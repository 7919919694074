import React from 'react'

import { Breakpoint } from 'packages/styles'
import { DateFormat, format } from 'packages/utils/dateHelpers'

import { useScheduleContext } from 'app/hkhub/components/schedule/components/common/VirtualizedSchedule/VirtualizedSchedule.context'
import { ScheduleRowEventsContainer } from 'app/hkhub/components/schedule/components/schedule.styles'
import { getHoursWorked } from 'app/hkhub/components/schedule/components/staff/staffSchedule.utils'
import { getTasksForDate } from 'app/hkhub/components/schedule/helpers/cleanHelpers'
import { DateCleanBuckets } from 'app/hkhub/store/cleans'
import { DateCoverageBuckets } from 'app/hkhub/store/coverage/coverage.types'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { DateVisitBuckets } from 'app/hkhub/store/visits/visits.types'

import { StaffScheduleRowHeader } from './StaffScheduleRowHeader'
import { StaffScheduleTasksContainer } from './StaffScheduleTasks.container'

const St = {
  TasksContainer: ScheduleRowEventsContainer,
}

export type StaffScheduleRowProps = {
  breakpoint: Breakpoint
  cleans: DateCleanBuckets
  coverage: DateCoverageBuckets
  hk: Housekeeper
  // this is the "style" object passed down by react-window
  style: any // eslint-disable-line
  visits: DateVisitBuckets
}

export const StaffScheduleRow: React.FC<StaffScheduleRowProps> = ({
  breakpoint,
  cleans,
  coverage,
  hk,
  style,
  visits,
}) => {
  const { dateRange, isLoading } = useScheduleContext()
  const unassigned = hk.id === 'unassigned'

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
    >
      <StaffScheduleRowHeader
        breakpoint={breakpoint}
        hk={hk}
        hours={getHoursWorked(dateRange, cleans)}
        isLoading={isLoading}
        unassigned={unassigned}
      />

      <St.TasksContainer>
        {dateRange.map(date => (
          <StaffScheduleTasksContainer
            breakpoint={breakpoint}
            cleans={getTasksForDate(cleans, date)}
            coverage={coverage[format(date, DateFormat.ApiUtcShort)] || []}
            date={date}
            hk={hk}
            key={format(date, DateFormat.ApiUtcShort)}
            unassigned={unassigned}
            visits={getTasksForDate(visits, date)}
          />
        ))}
      </St.TasksContainer>
    </div>
  )
}
