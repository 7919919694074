import styled from '@emotion/styled'
import React from 'react'

import { Alert, Button, Loader } from 'packages/common'
import { colors } from 'packages/styles'
import { AsyncState } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/hkhub/i18n'

import { DrawerSection, DrawerSectionTitle } from '../CleanDrawer.styles'
import { CleanNotesFormState } from './cleanNotes.reducer'

const St = {
  Alert: styled(Alert)`
    font-size: 16px;
    margin: 8px 0 0 0;
  `,

  Buttons: styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
  `,

  DrawerSection: styled(DrawerSection)`
    position: relative;
  `,

  EditButton: styled(Button)`
    font-weight: 700;
    margin-left: auto;
    padding-left: 0;
  `,

  Form: styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  `,

  Label: styled.label`
    display: none;
    visibility: hidden;
  `,

  Notes: styled.p<{ text: string }>`
    color: ${({ text }) => (text.length ? colors.dusk : colors.dusk60)};
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 8px;
    word-break: break-word;
  `,

  Subtitle: styled.div`
    color: ${colors.dusk70};
    font-weight: 400;
    margin: 4px 0;
  `,

  TextArea: styled.textarea`
    border: 1px solid ${colors.midnight20};
    border-radius: 4px;
    flex-grow: 1;
    font-size: 16px;
    padding: 8px 16px;
  `,

  Title: styled(DrawerSectionTitle)<{ editing: boolean }>`
    font-size: ${({ editing }) => (editing ? '14px' : '16px')};
    line-height: 24px;
    text-transform: ${({ editing }) => (editing ? 'uppercase' : 'none')};
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    cancel: ut(Slugs.cancel),
    editNote: ut(Slugs.cleanNotesEditNote),
    error: ut(Slugs.errorGeneric),
    fallback1: ut(Slugs.cleanNotesFallback1),
    fallback2: ut(Slugs.cleanNotesFallback2),
    placeholder: ut(Slugs.cleanNotesPlaceholder),
    saveNote: ut(Slugs.cleanNotesSaveNote),
    subtitle: ut(Slugs.cleanNotesSubtitle),
    title: ut(Slugs.cleanNotesTitle),
  }
}

export type CleanNotesProps = {
  canEdit: boolean
  formState: CleanNotesFormState
  handlers: {
    cancel: () => void
    change: React.ChangeEventHandler
    clearError: () => void
    edit: () => void
    submit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
  }
  loading: boolean
  requestState: AsyncState<unknown>
}

export const CleanNotes: React.FC<CleanNotesProps> = React.memo(
  ({ canEdit, formState, handlers, loading, requestState }) => {
    const { initialValues, uiState } = formState
    const { error } = requestState

    const strings = useTranslations()
    const fallbackText =
      strings.fallback1 + (canEdit ? ` ${strings.fallback2}` : '')
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null)

    // when showing the form, focus the textarea and set the cursor to end of existing text
    React.useEffect(() => {
      if (uiState !== 'edit') return
      if (!textAreaRef.current) return

      const el = textAreaRef.current
      el.focus()
      el.selectionStart = el.value.length
    }, [uiState])

    return (
      <St.DrawerSection>
        <St.Title editing={uiState === 'edit'}>{strings.title}</St.Title>

        {uiState === 'edit' ? (
          <St.Form onSubmit={handlers.submit}>
            <St.Label htmlFor="notes">{strings.title}</St.Label>
            <St.TextArea
              id="notes"
              maxLength={255}
              name="notes"
              onChange={handlers.change}
              placeholder={strings.placeholder}
              ref={textAreaRef}
              rows={4}
              value={formState.values.notes}
            />
            <St.Subtitle>{strings.subtitle}</St.Subtitle>

            {error && (
              <St.Alert alertType={'danger'} onClose={handlers.clearError}>
                <span>{strings.error}</span>
              </St.Alert>
            )}

            <St.Buttons>
              <Button
                disabled={loading}
                onClick={handlers.cancel}
                buttonType={'utility'}
              >
                {strings.cancel}
              </Button>
              <Button
                disabled={!formState.canSubmit || loading}
                isFormSubmit={true}
                buttonType={'primary'}
              >
                {strings.saveNote}
              </Button>
            </St.Buttons>

            {loading && <Loader />}
          </St.Form>
        ) : (
          <>
            <St.Notes text={initialValues.notes || ''}>
              {initialValues.notes || fallbackText}
            </St.Notes>

            {canEdit && (
              <St.EditButton onClick={handlers.edit} buttonType={'text'}>
                {strings.editNote}
              </St.EditButton>
            )}
          </>
        )}
      </St.DrawerSection>
    )
  },
)
